import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {toast} from "react-toastify";

export default function DownloadWorker() {

    const downloadAndRegisterWorker = () => {

        if ('serviceWorker' in navigator) {
            console.log("load serviceworker")
            navigator.serviceWorker.register('./sw.js', {
                scope: '/',
            }).catch(e => {
                console.error(e);
                toast.error("Service Worker initialization or download failed.");
            }).then(e => {
                toast.success("Worker registered")
            });
        }

    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Permission:
            </Typography>
            <Button onClick={downloadAndRegisterWorker} variant="contained">Press me to Download the worker</Button>
        </React.Fragment>
    );
}