import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {useState} from "react";
import ReactJson from "react-json-view";

export default function SubscribeToPushService() {

    let [key,setKey] = useState("{}");

    async function subscribe() {
        console.log("pressed")

        if (!('serviceWorker' in navigator)) {
            console.error("no serviceWorker present");
            toast.error("No Service worker present");
            return;
        }

        try {
            const registration = await navigator.serviceWorker.ready
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: "BLgUCjqKZ7PpaetXbILYh40zM6KdvCFW6yYWwaAvr3pk9Mplqc2_SMIlT6_2v_jViUI188Q4iClB-D9mkIqH51U"
            })
            key = JSON.stringify(subscription);
            localStorage.setItem("key", key);
            setKey(key)
            toast.success("Concrats! You got your URL and generated your key!")
        } catch(e) {
            console.error(e);
            toast.error("No Service worker present");
        }
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
            </Typography>
            <div className="align">
                <ReactJson src={JSON.parse(key)}/>
            </div>
            <br />
            <Button onClick={subscribe} variant="contained">Subscribe to Push Service</Button>
        </React.Fragment>
    );
}