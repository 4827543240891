import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import {toast} from "react-toastify";
import {useState} from "react";

export default function AskForPermission() {

    async function askForPermission() {
        console.log("Pressed ask for permission");
        let e = await Notification.requestPermission();
        if (e == "denied") {
            toast.error("Permission blocked")
        }
    }

    let [name, setName] = useState(localStorage.getItem("name") ?? "");

  return (

    <React.Fragment>
      <Typography variant="h6" gutterBottom>
          Permission:
      </Typography>
        Whats your name?:
        <br />
        <input value={name} onChange={(e) => {
            setName(e.target.value.toUpperCase());
            localStorage.setItem("name", e.target.value.toUpperCase());
        }}></input>
        <br />
        <br />
        <Button onClick={askForPermission} variant="contained">Press me to ask for Permission</Button>
    </React.Fragment>
  );
}