import React from 'react';
import './App.css';
import Permission from "./Permission";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <Permission></Permission>
        <ToastContainer />
    </div>
  );
}

export default App;
