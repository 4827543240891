import * as React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {TextareaAutosize} from "@mui/material";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import ReactJson from 'react-json-view'

export default function SendStuff() {

    let [data, setData] = useState("")
    let [key, setKey] = useState(localStorage.getItem("key") ?? "");
    let [otherKey, setOtherKey] = useState("{}");
    let [serverKeys, setServerKeys] = useState([]);


    useEffect(() => {
        let abort = false;
        const sync = async () => {
            return new Promise(resolve => {
                let k =  JSON.parse(key);
                delete k['expirationTime'];
                let title = localStorage.getItem("name");
                fetch("/sync", {
                    method: 'POST',
                    body: JSON.stringify({"message": data, "title": title , token: k}),
                    headers: {'Content-Type': 'application/json; charset=UTF-8'}
                }).then(response => response.json()
                ).then((k) => {
                    console.log(k);
                    console.log(typeof k);
                    setServerKeys([]);
                    setServerKeys(k);
                })
                setTimeout(() => {
                    if (abort == false) {
                        sync();
                    }
                }, 2000)
            });
        }
        sync();

        return () => {
            abort = true;
            console.log('Child unmounted');
        };

    }, [])

    const send = async () => {
        try {
            let k =  JSON.parse(otherKey);
            delete k['expirationTime'];
            let title =  "Message from: " + localStorage.getItem("name");
            const response = await fetch("/sendMessage", {
                    method: 'POST',
                    body: JSON.stringify({"message": data, "title": title , token: k}),
                    headers: {'Content-Type': 'application/json; charset=UTF-8'}
                }
            );
            if (response.status !== 200) {
                response.json().then(response => {
                    toast.error("Error while sending message:" + JSON.stringify(response));
                    console.error(response);
                })
            } else {
                console.log(response);
                toast.success("Message sent")
            }
        } catch (e) {
            console.error(e);
            toast.error("Error while sending message");
        }
    };

    let stretch = {
        'width' : '100%',
        'height' : '200px'
    }

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Send Message to someone:
            </Typography>
            My Key:
            <br/>
            <div className="align">
                <ReactJson src={JSON.parse(key)}/>
            </div>
            <br/>
            Key from someone else:
            <br/>
            <div className="align">
                <ReactJson src={JSON.parse(otherKey)}/>
            </div>
            <br />
            Send to:
            <div className="flex">
                {serverKeys.map((d: { title: string, token: {  } }) => {
                    {console.log(d)}
                    return (
                        <li>
                            <Button onClick={() => setOtherKey(JSON.stringify(d.token))} variant="contained" >
                                {d.title}
                            </Button>
                        </li>
                    )
                })}
            </div>
            <br/>
            My Message:
            <br />
            <TextareaAutosize style={stretch} value={data} onChange={(e) => setData(e.target.value)}></TextareaAutosize>
            <br />
            <Button onClick={send} variant="contained">Send</Button>
        </React.Fragment>

    );
}